import http from "./HttpService";
import sessionManager from "../utils/SessionManager";


const getAllUserGames = async () => {
    const headers = {
        'x-user-id': sessionManager.getCurrentUserProfileId()
    };
    const data = await http.get("/games", {headers});
    return data;
};

const getGamesWithParams = async (statusOrFavourite, params) => {
    let {byYear, byPlatform, byGenre, byTheme, byCompany, byDifficulty, byRating} = params;

    let endpoint = "/games".concat(
        statusOrFavourite === "favourites" ? "?favourite=true" : "?status=" + statusOrFavourite,
        byYear ? "&year=" + byYear : "",
        byPlatform ? "&platform=" + byPlatform : "",
        byGenre ? "&genre=" + byGenre : "",
        byTheme ? "&theme=" + byTheme : "",
        byCompany ? "&company=" + byCompany : "",
        byDifficulty ? "&difficulty=" + byDifficulty : "",
        byRating ? "&rating=" + byRating : ""
    );

    const headers = {
        'x-user-id': sessionManager.getCurrentUserProfileId()
    };
    const data = await http.get(endpoint, {headers});
    return data;
};

const getGameByID = async (id) => {
    const headers = {
        'x-user-id': sessionManager.getCurrentUserProfileId()
    };
    const data = await http.get("/games/" + id, {headers});
    return data;
};

const getGamesByExtenalID = async (id, includeUser) => {
    let endpoint = "/games/" + id + "/external".concat(
        includeUser ? "?user=" + sessionManager.getCurrentUserProfileId() : ""
    );

    const data = await http.get(endpoint);
    return data;
};

const addGame = async (data) => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const result = await http.post("/games", data, {headers});
    generateGamingStats();
    return result;
};

const deleteGame = async (id) => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const result = await http.delete("/games/" + id, {headers});
    generateGamingStats();
    return result;
};

const updateGame = async (id, data) => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const result = await http.put("/games/" + id, data, {headers});
    generateGamingStats();
    return result;
};

const searchGamesByName = async (name) => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get("/services/search?name=" + name, {headers});
    return data;
};

const getServiceGameById = async (id) => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get("/services/game?slug=" + id + "&details=complete", {headers});
    return data;
};

const getPlatforms = async () => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get("/services/platforms", {headers});
    return data;
};

const getGenres = async () => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get("/services/genres", {headers});
    return data;
};

const getCompanies = async () => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get("/services/companies", {headers});
    return data;
};

const getThemes = async () => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get("/services/themes", {headers});
    return data;
};

const processFile = async (data, operation) => {
    const headers = {
        "Content-Type": "multipart/form-data",
        "Accept": "application/json",
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const result = await http.post("/games/import?operation=" + operation, data, {headers});
    generateGamingStats();
    return result;
};

const requestFile = async () => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const result = await http.get("/games/export", {headers});
    return result;
};

const getGamingStats = async () => {
    const headers = {
        'x-user-id': sessionManager.getCurrentUserProfileId()
    };
    const data = await http.get("/games/stats", {headers});
    return data;
};

const generateGamingStats = async () => {
    const headers = {
        'x-user-id': sessionManager.getCurrentUserProfileId()
    };
    http.put("/games/stats", {}, {headers});
};

const getUserProfile = async () => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get("/accounts/profile", {headers});
    return data;
};

const upsertUserProfile = async (data) => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const result = await http.put("/accounts/profile", data, {headers});
    return result;
};

const searchAccountsByName = async (name) => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const data = await http.get("/accounts/search?name=" + name, {headers});
    return data;
};

const getUserProfileById = async (userId) => {
    const headers = {
        'x-user-id': userId
    };
    const data = await http.get("/accounts/profile", {headers});
    return data;
};

const followUserAccount = async (data) => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const result = await http.post("/accounts/connections", data, {headers});
    return result;
};

const unfollowUserAccount = async (data) => {
    const headers = {
        'x-user-id': sessionManager.getAuthenticatedUserId()
    };
    const result = await http.delete("/accounts/connections", {data, headers});
    return result;
};

const getUserConnections = async () => {
    const headers = {
        'x-user-id': sessionManager.getCurrentUserProfileId()
    };
    const data = await http.get("/accounts/connections", {headers});
    return data;
};

const service = {
    getAllUserGames: getAllUserGames,
    getGamesWithParams: getGamesWithParams,
    getGameByID: getGameByID,
    getGamesByExtenalID: getGamesByExtenalID,
    addGame: addGame,
    deleteGame: deleteGame,
    updateGame: updateGame,
    searchGamesByName: searchGamesByName,
    getServiceGameById: getServiceGameById,
    getPlatforms: getPlatforms,
    getGenres: getGenres,
    getCompanies: getCompanies,
    getThemes: getThemes,
    processFile: processFile,
    requestFile: requestFile,
    getGamingStats: getGamingStats,
    generateGamingStats: generateGamingStats,
    getUserProfile: getUserProfile,
    upsertUserProfile: upsertUserProfile,
    searchAccountsByName: searchAccountsByName,
    getUserProfileById: getUserProfileById,
    followUserAccount: followUserAccount,
    unfollowUserAccount: unfollowUserAccount,
    getUserConnections: getUserConnections,
};

export default service;
import { useNavigate, Link } from "react-router-dom";
import GameService from "../../services/GameService";
import {
  Checkbox,
  Chip,
  IconContainerProps,
  Rating,
  Slider,
} from "@mui/material";
import { styled } from "@mui/system";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { Tooltip } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import { IGame } from "../../types/Game";
import SelectOptions from "../../data/SelectOptions";
import useAlert from "../../hooks/userAlert";
import { Button } from "react-bootstrap";
import { IPlatformOption } from "../../types/Option";
import sessionManager from "../../utils/SessionManager";
import {
  formatDateWithMonthName,
  formatGameDuration,
} from "../../utils/Formatter";
import ImageModal from "../modal/ImageModal";
import StatRate from "../stats/StatRate";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import FormatListNumberedOutlinedIcon from "@mui/icons-material/FormatListNumberedOutlined";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import { findGameDeveloper } from "../../utils/GamesUtils";
import VideogameAssetOutlinedIcon from "@mui/icons-material/VideogameAssetOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined";
import SportsScoreOutlinedIcon from "@mui/icons-material/SportsScoreOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import SportsEsportsOutlinedIcon from "@mui/icons-material/SportsEsportsOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AlbumOutlinedIcon from "@mui/icons-material/AlbumOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import MusicNoteOutlinedIcon from "@mui/icons-material/MusicNoteOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import GamepadOutlinedIcon from "@mui/icons-material/GamepadOutlined";
import SentimentVeryDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentVeryDissatisfiedOutlined";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import RecyclingOutlinedIcon from "@mui/icons-material/RecyclingOutlined";
import RuleOutlinedIcon from "@mui/icons-material/RuleOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { StarBorder } from "@mui/icons-material";
import { getRatingLabel, getDifficultyLabel } from "../../utils/GamesUtils";

type Props = {
  action: string;
  gameId?: string;
  game: IGame;
  setGame: (game: IGame) => void;
  platforms: IPlatformOption[];
};

const UserGame = (props: Props) => {
  const { action } = props;
  const { gameId } = props;
  const { game } = props;
  const setGame = props.setGame;
  const { platforms } = props;

  const ratingEmptyColor = "#aeaeae";

  const canEdit = sessionManager.isAuthUserCurrentProfileOwner();
  const readOnly = action === "VIEW";

  const { setAlert } = useAlert();
  const successMessage = "Game added/updated successfully";
  const errorMessage = "We had a problem while adding/updating the game";

  const navigate = useNavigate();

  const editGame = "/main/games/update/" + encodeURI(props.game.sk);
  const viewGamePage = "/main/games/page/" + encodeURI(props.game.game_slug);

  const openEditMode = async (e: any) => {
    navigate(editGame);
  };

  const openGamePage = async (e: any) => {
    navigate(viewGamePage);
  };

  const CustomSlider = styled(Slider)({
    color: "#dcdcdc",
    height: 6,
    "& .MuiSlider-track": {
      border: "none",
      backgroundColor: "#ff9f59",
    },
  });

  const StyledChip = styled(Chip)({
    marginRight: 3,
  });

  const PointerChip = styled(StyledChip)({
    cursor: "pointer",
  });

  const handleResponseError = (error: any, message: string) => {
    setAlert(message, "error");
  };

  const upsertData = async (e: any) => {
    if (action === "ADD") {
      addGame(e);
    } else if (action === "UPDATE") {
      updateGame(e);
    }
  };

  const updateGame = async (e: any) => {
    e.preventDefault();
    try {
      await GameService.updateGame(gameId, game);
      setAlert(successMessage, "success");
      navigate(-1);
    } catch (error) {
      handleResponseError(error, errorMessage);
    }
  };

  const addGame = async (e: any) => {
    e.preventDefault();
    try {
      await GameService.addGame(game);
      setAlert(successMessage, "success");
      navigate(-1);
    } catch (error) {
      handleResponseError(error, errorMessage);
    }
  };

  const closeForm = async (e: any) => {
    navigate(-1);
  };

  const getPlatformDetails = (platform: any) => {
    const plat = platforms.find((item) => {
      return item.label === platform;
    });
    if (plat) {
      return plat;
    }
  };

  const onChangePlatform = (e: any) => {
    const platform = getPlatformDetails(e.target.value);
    setGame({
      ...game,
      [e.target.name]: e.target.value,
      game_platform_logo_url: platform?.logo,
      game_platform_slug: platform?.slug,
    });
  };

  const onChangeGameProgress = (
    event: Event | React.SyntheticEvent<Element, Event>,
    value: number | number[]
  ) => {
    if (value) {
      setGame({
        ...game,
        game_progress_percentage: value.toString(),
      });
    }
  };

  const onChange = (e: any) => {
    setGame({ ...game, [e.target.name]: e.target.value });
  };

  const handleCheckbox = (e: any) => {
    setGame({ ...game, [e.target.name]: e.target.checked });
  };

  const GameBadges = () => {
    return (
      <>
        <span className="p-1">
          {game.game_status === "playing" && (
            <Tooltip title="Now Playing" arrow>
              <PlayCircleOutlinedIcon fontSize="large" />
            </Tooltip>
          )}
          {game.game_status === "completed" && (
            <Tooltip title="Completed" arrow>
              <EmojiEventsOutlinedIcon fontSize="large" />
            </Tooltip>
          )}
          {game.game_status === "retired" && (
            <Tooltip title="Retired" arrow>
              <SaveAltIcon fontSize="large" />
            </Tooltip>
          )}
          {game.game_status === "backlog" && (
            <Tooltip title="Backlog" arrow>
              <FormatListNumberedOutlinedIcon fontSize="large" />
            </Tooltip>
          )}
          {game.game_favourite && (
            <Tooltip title="Favourite" arrow>
              <FavoriteOutlinedIcon fontSize="large" />
            </Tooltip>
          )}
        </span>
      </>
    );
  };

  const gameProgressText = (value: number) => {
    return `${value}%`;
  };

  const DifficultyRating = styled(Rating)(({ theme }) => ({
    "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
      color: ratingEmptyColor,
    },
  }));

  const customIcons: {
    [index: string]: {
      icon: React.ReactElement;
      label: string;
    };
  } = {
    1: {
      icon: <SentimentVerySatisfiedIcon fontSize="large" color="success" />,
      label: "Easy",
    },
    2: {
      icon: <SentimentSatisfiedAltIcon fontSize="large" color="success" />,
      label: "Moderate",
    },
    3: {
      icon: <SentimentSatisfiedIcon fontSize="large" color="warning" />,
      label: "Challenging",
    },
    4: {
      icon: <SentimentDissatisfiedIcon fontSize="large" color="error" />,
      label: "Hard",
    },
    5: {
      icon: (
        <SentimentVeryDissatisfiedOutlinedIcon fontSize="large" color="error" />
      ),
      label: "Very Hard",
    },
  };

  const IconContainer = (props: IconContainerProps) => {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  };

  return (
    <>
      <div className="container">
        <div className="row mb-1">
          <h6 className="page-header">{action} Game Collection</h6>
        </div>
        <section id="header" className="game-solid-background">
          <div className="container p-4">
            <div className="row">
              <div className="col-lg-9">
                <h2 className="game-title display-6 fw-bold text-body-emphasis">
                  {game.game_title}
                  &nbsp;
                  <GameBadges />
                </h2>
                <h6 className="game-title">
                  {formatDateWithMonthName(game.game_release)}
                </h6>
                <h4 className="game-title">
                  {findGameDeveloper(game.game_companies)}
                  &nbsp;
                  {game.game_category && (
                    <Chip
                      className="chip-outline-custom"
                      variant="outlined"
                      size="small"
                      key={game.game_category}
                      label={game.game_category}
                    />
                  )}
                </h4>
                <div>
                  {game.game_genres?.concat(game.game_themes).map((value) => (
                    <Link
                      className="link"
                      state={{ byGenre: value }}
                      to={`/main/games/total`}
                    >
                      <PointerChip
                        className="chip-outline-custom"
                        variant="outlined"
                        size="small"
                        key={value}
                        label={value}
                      />
                    </Link>
                  ))}
                </div>
                <div className="row g-0 pt-4">
                  <div className="col d-flex justify-content-center col-sm-12 col-md-6 col-lg-3">
                    {game.game_platform_logo_url ? (
                      <div>
                        <Link
                          className="link"
                          state={{
                            byPlatform: game.game_platform,
                            byPlatformLogo: game.game_platform_logo_url,
                          }}
                          to={`/main/games/total`}
                        >
                          <img
                            src={game.game_platform_logo_url}
                            alt="platform logo"
                            width="150"
                          />
                        </Link>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div className="col d-flex justify-content-center col-sm-12 col-md-6 col-lg-3 mb-2">
                    <Link
                      className="link"
                      state={{ byRating: game.game_rating }}
                      to={`/main/games/total`}
                    >
                      <StatRate
                        height={150}
                        width={150}
                        rate={parseInt(game.game_rating)}
                        isPercentage={false}
                        minValue={0}
                        maxValue={10}
                        rateFontSize={35}
                        trailColor={colorConfigs.stats.statRateTrail}
                        pathColor={colorConfigs.stats.statRatePath}
                        textColor={colorConfigs.stats.statRateText}
                        icon={
                          <StarOutlineOutlinedIcon sx={{ color: "white" }} />
                        }
                        text={getRatingLabel(game.game_rating)}
                      />
                    </Link>
                  </div>
                  <div className="col d-flex justify-content-center col-sm-12 col-md-6 col-lg-3 mb-2">
                    <Link
                      className="link"
                      state={{ byDifficulty: game.game_difficulty }}
                      to={`/main/games/total`}
                    >
                      <StatRate
                        height={150}
                        width={150}
                        rate={parseInt(game.game_difficulty)}
                        isPercentage={false}
                        minValue={0}
                        maxValue={5}
                        rateFontSize={35}
                        trailColor={colorConfigs.stats.statRateTrail}
                        pathColor={colorConfigs.stats.statRatePath}
                        textColor={colorConfigs.stats.statRateText}
                        icon={<SpeedOutlinedIcon sx={{ color: "white" }} />}
                        text={getDifficultyLabel(game.game_difficulty)}
                      />
                    </Link>
                  </div>
                  <div className="col d-flex justify-content-center col-sm-12 col-md-6 col-lg-3 mb-2">
                    <StatRate
                      height={150}
                      width={150}
                      rate={formatGameDuration(game.game_duration)}
                      isPercentage={false}
                      minValue={0}
                      maxValue={0}
                      rateFontSize={35}
                      trailColor={colorConfigs.stats.statRateTrail}
                      pathColor={colorConfigs.stats.statRatePath}
                      textColor={colorConfigs.stats.statRateText}
                      icon={<TimerOutlinedIcon sx={{ color: "white" }} />}
                      text="Hour(s)"
                    />
                  </div>
                </div>
              </div>
              <div className="col col-lg-3">
                <ImageModal imageURL={game.game_cover_url} />
                <div className="row justify-content-center mt-1">
                  {action === "VIEW" && (
                    <Tooltip title="Edit User Game" arrow>
                      <Button
                        className="btn btn-sm button-outline-custom m-1"
                        style={{ width: 50 }}
                        onClick={(e) => openEditMode(e)}
                      >
                        <EditOutlinedIcon />
                      </Button>
                    </Tooltip>
                  )}

                  <Button
                    className="btn btn-sm button-outline-custom m-1"
                    style={{ width: 170 }}
                    onClick={(e) => openGamePage(e)}
                  >
                    <SportsEsportsOutlinedIcon /> GAME INFO
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="info" className="game-solid-background">
          <div className="container p-4 mt-4">
            <div className="row">
              <h3 className="section-title">Game Info</h3>
            </div>
            <div className="row">
              <div className="col-sm-12 col-xl-6 my-2">
                <div className="form-group">
                  <div className="mb-2 d-flex align-items-center">
                    <VideogameAssetOutlinedIcon sx={{ marginRight: 0.5 }} />
                    <label>Game Platform</label>
                  </div>
                  <select
                    disabled={readOnly || action === "UPDATE"}
                    className="form-select"
                    name="game_platform"
                    value={game.game_platform}
                    id="game_platform"
                    onChange={onChangePlatform}
                  >
                    {[""].concat(game.game_released_platforms).map((option) => {
                      return (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-sm-12 col-xl-6 my-2">
                <div className="form-group">
                  <div className="mb-2 d-flex align-items-center">
                    <SportsEsportsOutlinedIcon sx={{ marginRight: 0.5 }} />
                    <label>Game Status</label>
                  </div>
                  <select
                    disabled={readOnly}
                    className="form-select"
                    name="game_status"
                    value={game.game_status}
                    id="game_status"
                    onChange={onChange}
                  >
                    {SelectOptions.statuses.map((option) => {
                      return (
                        <option key={option.label} value={option.value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-sm-12 col-xl-6 my-2">
                <div className="form-group">
                  <div className="mb-2 d-flex align-items-center">
                    <ShoppingBagOutlinedIcon sx={{ marginRight: 0.5 }} />
                    <label>Game Ownership</label>
                  </div>
                  <select
                    disabled={readOnly}
                    className="form-select"
                    name="game_ownership"
                    value={game.game_ownership}
                    id="game_ownership"
                    onChange={onChange}
                  >
                    {SelectOptions.ownership.map((option) => {
                      return (
                        <option key={option.label} value={option.value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-sm-12 col-xl-6 my-2">
                <div className="form-group">
                  <div className="mb-2 d-flex align-items-center">
                    <AlbumOutlinedIcon sx={{ marginRight: 0.5 }} />
                    <label>Game Format</label>
                  </div>
                  <select
                    disabled={readOnly}
                    className="form-select"
                    name="game_format"
                    value={game.game_format}
                    id="game_format"
                    onChange={onChange}
                  >
                    {SelectOptions.format.map((option) => {
                      return (
                        <option key={option.label} value={option.value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-sm-12 col-lg-4 my-2">
                <div className="form-group">
                  <label>Favourite</label>
                  <Tooltip title="Favourite" arrow>
                    <Checkbox
                      disabled={readOnly}
                      name="game_favourite"
                      icon={
                        <FavoriteBorderOutlinedIcon sx={{ color: "white" }} />
                      }
                      checkedIcon={
                        <FavoriteOutlinedIcon
                          sx={{ color: colorConfigs.sidebar.bg }}
                        />
                      }
                      onChange={handleCheckbox}
                      checked={game.game_favourite}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </section>
        {game.game_status !== "backlog" && (
          <section id="progress" className="game-solid-background">
            <div className="container p-4 mt-4">
              <div className="row">
                <h3 className="section-title">Game Progress</h3>
              </div>
              <div className="row">
                <div className="col-sm-12 col-lg-4 my-2">
                  <div className="form-group">
                    <div className="mb-2 d-flex align-items-center">
                      <CalendarTodayIcon sx={{ marginRight: 0.5 }} />
                      <label>Started Date</label>
                    </div>
                    <input
                      disabled={readOnly}
                      type="date"
                      name="game_started_date"
                      value={game.game_started_date}
                      className="form-control"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-lg-4 my-2">
                  <div className="form-group">
                    <div className="mb-2 d-flex align-items-center">
                      <RotateLeftOutlinedIcon sx={{ marginRight: 0.5 }} />
                      <label>Game Progress</label>
                    </div>
                    <>
                      <CustomSlider
                        disabled={readOnly}
                        aria-label="Percentage"
                        defaultValue={parseInt(game.game_progress_percentage)}
                        onChangeCommitted={onChangeGameProgress}
                        valueLabelFormat={gameProgressText}
                        valueLabelDisplay="on"
                        step={1}
                        min={0}
                        max={100}
                      />
                    </>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-4 my-2">
                  <div className="form-group">
                    <div className="mb-2 d-flex align-items-center">
                      <TimerOutlinedIcon sx={{ marginRight: 0.5 }} />
                      <label>Playtime</label>
                    </div>
                    <div className="row no-gutters">
                      <div className="col-6">
                        <input
                          disabled={readOnly}
                          type="number"
                          name="game_duration"
                          value={game.game_duration}
                          className="form-control"
                          placeholder="Hours"
                          onChange={onChange}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          disabled={readOnly}
                          type="number"
                          name="game_duration_minutes"
                          value={game.game_duration_minutes}
                          className="form-control"
                          placeholder="Minutes"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <div className="mb-2 d-flex align-items-center">
                    <RotateLeftOutlinedIcon sx={{ marginRight: 0.5 }} />
                    <label>Progress Notes</label>
                  </div>
                  <textarea
                    disabled={readOnly}
                    name="game_progress"
                    rows={4}
                    value={game.game_progress}
                    className="form-control"
                    placeholder="Game progress additonal notes"
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
          </section>
        )}
        {game.game_status === "completed" && (
          <section id="completed" className="game-solid-background">
            <div className="container p-4 mt-4">
              <div className="row">
                <h3 className="section-title">Game Completed</h3>
              </div>
              <div className="row">
                <div className="col-sm-12 col-lg-4 my-2">
                  <div className="form-group">
                    <div className="mb-2 d-flex align-items-center">
                      <EventAvailableIcon sx={{ marginRight: 0.5 }} />
                      <label>Completed Date</label>
                    </div>
                    <input
                      disabled={readOnly}
                      type="date"
                      name="game_completed_date"
                      value={game.game_completed_date}
                      className="form-control"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-lg-4 my-2">
                  <div className="form-group">
                    <label>Save/Load State</label>
                    <Tooltip
                      title="Whether or not Save/Load state was applied during the gameplay"
                      arrow
                    >
                      <Checkbox
                        disabled={readOnly}
                        name="game_use_save_state"
                        icon={
                          <NotInterestedOutlinedIcon sx={{ color: "white" }} />
                        }
                        checkedIcon={
                          <SaveOutlinedIcon
                            sx={{ color: colorConfigs.sidebar.bg }}
                          />
                        }
                        onChange={handleCheckbox}
                        checked={game.game_use_save_state}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {game.game_status !== "backlog" && (
          <>
            <section id="ratings" className="game-solid-background">
              <div className="container p-4 mt-4">
                <div className="row">
                  <h3 className="section-title">Game Ratings</h3>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-lg-4 my-2">
                    <div className="form-group">
                      <div className="mb-2 d-flex align-items-center">
                        <StarOutlineOutlinedIcon sx={{ marginRight: 0.5 }} />
                        <label>Overall Rating</label>
                      </div>
                      <Rating
                        name="overall-ratings"
                        disabled={readOnly}
                        emptyIcon={
                          <StarBorder sx={{ color: ratingEmptyColor }} />
                        }
                        max={10}
                        value={parseInt(game.game_rating)}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setGame({
                              ...game,
                              game_rating: newValue.toString(),
                            });
                          }
                        }}
                      />
                      <div className="mb-2 rating">
                        {getRatingLabel(game.game_rating)}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-4 my-2">
                    <div className="form-group">
                      <div className="mb-2 d-flex align-items-center">
                        <DesktopWindowsOutlinedIcon sx={{ marginRight: 0.5 }} />
                        <label>Visual</label>
                      </div>
                      <Rating
                        name="visual-ratings"
                        disabled={readOnly}
                        emptyIcon={
                          <StarBorder sx={{ color: ratingEmptyColor }} />
                        }
                        max={10}
                        value={parseInt(game.game_rating_visual)}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setGame({
                              ...game,
                              game_rating_visual: newValue.toString(),
                            });
                          }
                        }}
                      />
                      <div className="mb-2 rating">
                        {getRatingLabel(game.game_rating_visual)}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-4 my-2">
                    <div className="form-group">
                      <div className="mb-2 d-flex align-items-center">
                        <MusicNoteOutlinedIcon sx={{ marginRight: 0.5 }} />
                        <label>Audio</label>
                      </div>
                      <Rating
                        name="audio-ratings"
                        disabled={readOnly}
                        emptyIcon={
                          <StarBorder sx={{ color: ratingEmptyColor }} />
                        }
                        max={10}
                        value={parseInt(game.game_rating_audio)}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setGame({
                              ...game,
                              game_rating_audio: newValue.toString(),
                            });
                          }
                        }}
                      />
                      <div className="mb-2 rating">
                        {getRatingLabel(game.game_rating_audio)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-lg-4 my-2">
                    <div className="form-group">
                      <div className="mb-2 d-flex align-items-center">
                        <GamepadOutlinedIcon sx={{ marginRight: 0.5 }} />
                        <label>Controls</label>
                      </div>
                      <Rating
                        name="control-ratings"
                        disabled={readOnly}
                        emptyIcon={
                          <StarBorder sx={{ color: ratingEmptyColor }} />
                        }
                        max={10}
                        value={parseInt(game.game_rating_control)}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setGame({
                              ...game,
                              game_rating_control: newValue.toString(),
                            });
                          }
                        }}
                      />
                      <div className="mb-2 rating">
                        {getRatingLabel(game.game_rating_control)}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-4 my-2">
                    <div className="form-group">
                      <div className="mb-2 d-flex align-items-center">
                        <AutoStoriesOutlinedIcon sx={{ marginRight: 0.5 }} />
                        <label>Story</label>
                      </div>
                      <Rating
                        name="story-ratings"
                        emptyIcon={
                          <StarBorder sx={{ color: ratingEmptyColor }} />
                        }
                        disabled={readOnly}
                        max={10}
                        value={parseInt(game.game_rating_story)}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setGame({
                              ...game,
                              game_rating_story: newValue.toString(),
                            });
                          }
                        }}
                      />
                      <div className="mb-2 rating">
                        {getRatingLabel(game.game_rating_story)}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-4 my-2">
                    <div className="form-group">
                      <div className="mb-2 d-flex align-items-center">
                        <SportsEsportsOutlinedIcon sx={{ marginRight: 0.5 }} />
                        <label>Gameplay</label>
                      </div>
                      <Rating
                        name="gameplay-ratings"
                        disabled={readOnly}
                        emptyIcon={
                          <StarBorder sx={{ color: ratingEmptyColor }} />
                        }
                        max={10}
                        value={parseInt(game.game_rating_gameplay)}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setGame({
                              ...game,
                              game_rating_gameplay: newValue.toString(),
                            });
                          }
                        }}
                      />
                      <div className="mb-2 rating">
                        {getRatingLabel(game.game_rating_gameplay)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-lg-6 my-2">
                    <div className="form-group">
                      <div className="mb-2 d-flex align-items-center">
                        <SpeedOutlinedIcon sx={{ marginRight: 0.5 }} />
                        <label>Difficulty</label>
                      </div>
                      <DifficultyRating
                        name="difficulty-rating"
                        size="large"
                        disabled={readOnly}
                        value={parseInt(game.game_difficulty)}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setGame({
                              ...game,
                              game_difficulty: newValue.toString(),
                            });
                          }
                        }}
                        IconContainerComponent={IconContainer}
                        getLabelText={(value: number) =>
                          customIcons[value].label
                        }
                        highlightSelectedOnly
                      />
                      <div className="mb-2 rating">
                        {getDifficultyLabel(game.game_difficulty)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="review" className="game-solid-background">
              <div className="container p-4 mt-4">
                <div className="row">
                  <h3 className="section-title">Game Review</h3>
                </div>
                <div className="row my-2">
                  <div className="form-group">
                    <div className="mb-2 d-flex align-items-center">
                      <ChatOutlinedIcon sx={{ marginRight: 0.5 }} />
                      <label>Remarks</label>
                    </div>
                    <textarea
                      disabled={readOnly}
                      name="game_remarks"
                      rows={4}
                      value={game.game_remarks}
                      className="form-control"
                      placeholder="Any game remarks?"
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
        {game.game_ownership === "Owned" && (
          <section id="acquisition" className="game-solid-background">
            <div className="container p-4 mt-4">
              <div className="row">
                <h3 className="section-title">Acquisition Info</h3>
              </div>
              <div className="row">
                <div className="col-sm-12 col-xl-6 my-2">
                  <div className="form-group">
                    <div className="mb-2 d-flex align-items-center">
                      <ShoppingCartOutlinedIcon sx={{ marginRight: 0.5 }} />
                      <label>Game Acquisition</label>
                    </div>
                    <select
                      disabled={readOnly}
                      className="form-select"
                      name="game_acquisition"
                      value={game.game_acquisition}
                      id="game_acquisition"
                      onChange={onChange}
                    >
                      {SelectOptions.acquisition.map((option) => {
                        return (
                          <option key={option.label} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 col-xl-6 my-2">
                  <div className="form-group">
                    <div className="mb-2 d-flex align-items-center">
                      <CalendarTodayIcon sx={{ marginRight: 0.5 }} />
                      <label>Acquisition Date</label>
                    </div>
                    <input
                      disabled={readOnly}
                      type="date"
                      name="game_acquisition_date"
                      value={game.game_acquisition_date}
                      className="form-control"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-xl-6 my-2">
                  <div className="form-group">
                    <div className="mb-2 d-flex align-items-center">
                      <LocationOnOutlinedIcon sx={{ marginRight: 0.5 }} />
                      <label>Acquisition Source</label>
                    </div>
                    <input
                      disabled={readOnly}
                      type="text"
                      name="game_acquisition_source"
                      value={game.game_acquisition_source}
                      className="form-control"
                      placeholder="Amazon, CeX, etc"
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-xl-6 my-2">
                  <div className="form-group">
                    <div className="mb-2 d-flex align-items-center">
                      <MonetizationOnOutlinedIcon sx={{ marginRight: 0.5 }} />
                      <label>Amount Paid</label>
                    </div>
                    <input
                      disabled={readOnly}
                      type="number"
                      name="game_amount_paid"
                      value={game.game_amount_paid}
                      className="form-control"
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {game.game_ownership === "Owned" && game.game_format === "Physical" && (
          <section id="collector" className="game-solid-background">
            <div className="container p-4 mt-4">
              <div className="row">
                <h3 className="section-title">Collector's Info</h3>
              </div>
              <div className="row">
                <div className="col-sm-12 col-xl-6 my-2">
                  <div className="form-group">
                    <div className="mb-2 d-flex align-items-center">
                      <RecyclingOutlinedIcon sx={{ marginRight: 0.5 }} />
                      <label>Acquisition State</label>
                    </div>
                    <select
                      disabled={readOnly}
                      className="form-select"
                      name="game_acquisition_state"
                      value={game.game_acquisition_state}
                      id="game_acquisition_state"
                      onChange={onChange}
                    >
                      {SelectOptions.acquisitionState.map((option) => {
                        return (
                          <option key={option.label} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 col-xl-6 my-2">
                  <div className="form-group">
                    <div className="mb-2 d-flex align-items-center">
                      <RuleOutlinedIcon sx={{ marginRight: 0.5 }} />
                      <label>Game Completeness</label>
                    </div>
                    <select
                      disabled={readOnly}
                      className="form-select"
                      name="game_acquisition_completeness"
                      value={game.game_acquisition_completeness}
                      id="game_acquisition_completeness"
                      onChange={onChange}
                    >
                      {SelectOptions.acquisitionCompleteness.map((option) => {
                        return (
                          <option key={option.label} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 col-xl-6 my-2">
                  <div className="form-group">
                    <div className="mb-2 d-flex align-items-center">
                      <PublicOutlinedIcon sx={{ marginRight: 0.5 }} />
                      <label>Game Region</label>
                    </div>
                    <select
                      disabled={readOnly}
                      className="form-select"
                      name="game_region"
                      value={game.game_region}
                      id="game_region"
                      onChange={onChange}
                    >
                      {SelectOptions.region.map((option) => {
                        return (
                          <option key={option.label} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 col-xl-6 my-2">
                  <div className="form-group">
                    <div className="mb-2 d-flex align-items-center">
                      <AlbumOutlinedIcon sx={{ marginRight: 0.5 }} />
                      <label>Media Condition</label>
                    </div>
                    <select
                      disabled={readOnly}
                      className="form-select"
                      name="game_condition"
                      value={game.game_condition}
                      id="game_condition"
                      onChange={onChange}
                    >
                      {SelectOptions.itemCondition.map((option) => {
                        return (
                          <option key={option.label} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 col-xl-6 my-2">
                  <div className="form-group">
                    <div className="mb-2 d-flex align-items-center">
                      <LibraryBooksOutlinedIcon sx={{ marginRight: 0.5 }} />
                      <label>Box Condition</label>
                    </div>
                    <select
                      disabled={readOnly}
                      className="form-select"
                      name="game_box_condition"
                      value={game.game_box_condition}
                      id="game_box_condition"
                      onChange={onChange}
                    >
                      {SelectOptions.itemCondition.map((option) => {
                        return (
                          <option key={option.label} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 col-xl-6 my-2">
                  <div className="form-group">
                    <div className="mb-2 d-flex align-items-center">
                      <MenuBookOutlinedIcon sx={{ marginRight: 0.5 }} />
                      <label>Manual Condition</label>
                    </div>
                    <select
                      disabled={readOnly}
                      className="form-select"
                      name="game_manual_condition"
                      value={game.game_manual_condition}
                      id="game_manual_condition"
                      onChange={onChange}
                    >
                      {SelectOptions.itemCondition.map((option) => {
                        return (
                          <option key={option.label} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {game.game_status === "backlog" && (
          <section id="backlog" className="game-solid-background">
            <div className="container p-4 mt-4">
              <div className="row">
                <h3 className="section-title">Backlog Game</h3>
              </div>
              <div className="row">
                <div className="col-sm-12 col-lg-4 my-2">
                  <div className="form-group">
                    <div className="mb-2 d-flex align-items-center">
                      <CalendarTodayIcon sx={{ marginRight: 0.5 }} />
                      <label>Entry Date</label>
                    </div>
                    <input
                      disabled={readOnly}
                      type="date"
                      name="game_backlog_date"
                      value={game.game_backlog_date}
                      className="form-control"
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {game.game_status === "retired" && (
          <section id="retired" className="game-solid-background">
            <div className="container p-4 mt-4">
              <div className="row">
                <h3 className="section-title">Game Retired</h3>
              </div>
              <div className="row">
                <div className="col-sm-12 col-lg-4 my-2">
                  <div className="form-group">
                    <div className="mb-2 d-flex align-items-center">
                      <EventBusyOutlinedIcon sx={{ marginRight: 0.5 }} />
                      <label>Retired Date</label>
                    </div>
                    <input
                      disabled={readOnly}
                      type="date"
                      name="game_retired_date"
                      value={game.game_retired_date}
                      className="form-control"
                      onChange={onChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row my-2">
                <div className="form-group">
                  <div className="mb-2 d-flex align-items-center">
                    <SportsScoreOutlinedIcon sx={{ marginRight: 0.5 }} />
                    <label>Remaining to Complete</label>
                  </div>
                  <textarea
                    disabled={readOnly}
                    name="game_remaining_complete"
                    rows={4}
                    value={game.game_remaining_complete}
                    className="form-control"
                    placeholder="What is left to complete the game?"
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
          </section>
        )}
        <form>
          <div className="container mt-5">
            <div className="row align-items-center">
              {!readOnly && canEdit && (
                <Button
                  className="button-outline-custom mb-2"
                  disabled={!game.game_platform || !game.game_status}
                  variant="contained"
                  onClick={(e) => upsertData(e)}
                >
                  {action} GAME
                </Button>
              )}
              <Button
                className="button-outline-custom"
                onClick={(e) => closeForm(e)}
              >
                CANCEL
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default UserGame;

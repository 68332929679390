import { ReactElement } from 'react';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';


type Props = {
    count: number;
    text: string;
    icon: ReactElement;
    link?: string;
};


const Counter = (props: Props) => {

    return (
        <Link style={{ textDecoration: 'none', color: 'white' }} to={`/main/games/${props.link}`}> 
            <div className="game-counter rounded p-3">
                <h1 className='mb-0'><CountUp duration={3} end={props.count}/></h1>
                <span className=' align-middle '>{props.icon} </span>
                <span className=' align-middle '>{props.text}</span>
            </div>
        </Link>
    );
};

export default Counter;

const statuses = [
    {value: "backlog", label: "Backlog"},
    {value: "playing", label: "Playing"},
    {value: "completed", label: "Completed"},
    {value: "retired", label: "Retired"},
]

const ratings = [
    {value: "", label: ""},
    {value: "(1/10) Awful", label: "(1/10) Awful"},
    {value: "(2/10) Bad", label: "(2/10) Bad"},
    {value: "(3/10) Poor", label: "(3/10) Poor"},
    {value: "(4/10) Passable", label: "(4/10) Passable"},
    {value: "(5/10) Average", label: "(5/10) Average"},
    {value: "(6/10) Decent", label: "(6/10) Decent"},
    {value: "(7/10) Good", label: "(7/10) Good"},
    {value: "(8/10) Very Good", label: "(8/10) Very Good"},
    {value: "(9/10) Excellent", label: "(9/10) Excellent"},
    {value: "(10/10) Epic", label: "(10/10) Epic"},
];

const difficulty = [
    {value: "", label: ""},
    {value: "(1/5) Easy", label: "(1/5) Easy"},
    {value: "(2/5) Moderate", label: "(2/5) Moderate"},
    {value: "(3/5) Challenging", label: "(3/5) Challenging"},
    {value: "(4/5) Hard", label: "(4/5) Hard"},
    {value: "(5/5) Very Hard", label: "(5/5) Very Hard"},
];

export const ratingsMap = {
    "1": "Awful",
    "2": "Bad",
    "3": "Poor",
    "4": "Passable",
    "5": "Average",
    "6": "Decent",
    "7": "Good",
    "8": "Very Good",
    "9": "Excellent",
    "10": "Epic",
};

export const difficultyMap = {
    "1": "Easy",
    "2": "Moderate",
    "3": "Challenging",
    "4": "Hard",
    "5": "Very Hard",
};

const ownership = [
    {value: "", label: ""},
    {value: "Owned", label: "Owned"},
    {value: "Subscription", label: "Subscription"},
    {value: "Borrowed", label: "Borrowed"},
    {value: "Rented", label: "Rented"},
    {value: "ROM", label: "ROM"},
    {value: "Previously Owned", label: "Previously Owned"},
];

const format = [
    {value: "", label: ""},
    {value: "Physical", label: "Physical"},
    {value: "Digital", label: "Digital"},
];

const acquisition = [
    {value: "", label: ""},
    {value: "Purchase", label: "Purchase"},
    {value: "Bundled", label: "Bundled"},
    {value: "Gift", label: "Gift"},
    {value: "Trade", label: "Trade"},
    {value: "Free", label: "Free"},
];

const acquisitionState = [
    {value: "", label: ""},
    {value: "New", label: "New"},
    {value: "Used", label: "Used"},
];

const acquisitionCompleteness = [
    {value: "", label: ""},
    {value: "Complete in Box", label: "Complete in Box"},
    {value: "Loose", label: "Loose"},
    {value: "Sealed", label: "Sealed"},
];

const itemCondition = [
    {value: "", label: ""},
    {value: "Mint", label: "Mint"},
    {value: "Good", label: "Good"},
    {value: "Acceptable", label: "Acceptable"},
    {value: "Poor", label: "Poor"},
];

const region = [
    {value: "", label: ""},
    {value: "PAL", label: "PAL"},
    {value: "NTSC-U", label: "NTSC-U"},
    {value: "NTSC-J", label: "NTSC-J"},
    {value: "NTSC-C", label: "NTSC-C"},
];

const countries = [
    {value: "", label: "", flag: ''},
    {flag: "https://flagcdn.com/w40/af.jpg", value: "Afghanistan", label: "Afghanistan"},
    {flag: "https://flagcdn.com/w40/ax.jpg", value: "Åland Islands", label: "Åland Islands"},
    {flag: "https://flagcdn.com/w40/al.jpg", value: "Albania", label: "Albania"},
    {flag: "https://flagcdn.com/w40/dz.jpg", value: "Algeria", label: "Algeria"},
    {flag: "https://flagcdn.com/w40/as.jpg", value: "American Samoa", label: "American Samoa"},
    {flag: "https://flagcdn.com/w40/ad.jpg", value: "Andorra", label: "Andorra"},
    {flag: "https://flagcdn.com/w40/ao.jpg", value: "Angola", label: "Angola"},
    {flag: "https://flagcdn.com/w40/ai.jpg", value: "Anguilla", label: "Anguilla"},
    {flag: "https://flagcdn.com/w40/aq.jpg", value: "Antarctica", label: "Antarctica"},
    {flag: "https://flagcdn.com/w40/ag.jpg", value: "Antigua and Barbuda", label: "Antigua and Barbuda"},
    {flag: "https://flagcdn.com/w40/ar.jpg", value: "Argentina", label: "Argentina"},
    {flag: "https://flagcdn.com/w40/am.jpg", value: "Armenia", label: "Armenia"},
    {flag: "https://flagcdn.com/w40/aw.jpg", value: "Aruba", label: "Aruba"},
    {flag: "https://flagcdn.com/w40/au.jpg", value: "Australia", label: "Australia"},
    {flag: "https://flagcdn.com/w40/at.jpg", value: "Austria", label: "Austria"},
    {flag: "https://flagcdn.com/w40/az.jpg", value: "Azerbaijan", label: "Azerbaijan"},
    {flag: "https://flagcdn.com/w40/bs.jpg", value: "Bahamas", label: "Bahamas"},
    {flag: "https://flagcdn.com/w40/bh.jpg", value: "Bahrain", label: "Bahrain"},
    {flag: "https://flagcdn.com/w40/bd.jpg", value: "Bangladesh", label: "Bangladesh"},
    {flag: "https://flagcdn.com/w40/bb.jpg", value: "Barbados", label: "Barbados"},
    {flag: "https://flagcdn.com/w40/by.jpg", value: "Belarus", label: "Belarus"},
    {flag: "https://flagcdn.com/w40/be.jpg", value: "Belgium", label: "Belgium"},
    {flag: "https://flagcdn.com/w40/bz.jpg", value: "Belize", label: "Belize"},
    {flag: "https://flagcdn.com/w40/bj.jpg", value: "Benin", label: "Benin"},
    {flag: "https://flagcdn.com/w40/bm.jpg", value: "Bermuda", label: "Bermuda"},
    {flag: "https://flagcdn.com/w40/bt.jpg", value: "Bhutan", label: "Bhutan"},
    {flag: "https://flagcdn.com/w40/bo.jpg", value: "Bolivia", label: "Bolivia"},
    {flag: "https://flagcdn.com/w40/ba.jpg", value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina"},
    {flag: "https://flagcdn.com/w40/bw.jpg", value: "Botswana", label: "Botswana"},
    {flag: "https://flagcdn.com/w40/bv.jpg", value: "Bouvet Island", label: "Bouvet Island"},
    {flag: "https://flagcdn.com/w40/br.jpg", value: "Brazil", label: "Brazil"},
    {flag: "https://flagcdn.com/w40/io.jpg", value: "British Indian Ocean Territory", label: "British Indian Ocean Territory"},
    {flag: "https://flagcdn.com/w40/vg.jpg", value: "British Virgin Islands", label: "British Virgin Islands"},
    {flag: "https://flagcdn.com/w40/bn.jpg", value: "Brunei", label: "Brunei"},
    {flag: "https://flagcdn.com/w40/bg.jpg", value: "Bulgaria", label: "Bulgaria"},
    {flag: "https://flagcdn.com/w40/bf.jpg", value: "Burkina Faso", label: "Burkina Faso"},
    {flag: "https://flagcdn.com/w40/bi.jpg", value: "Burundi", label: "Burundi"},
    {flag: "https://flagcdn.com/w40/kh.jpg", value: "Cambodia", label: "Cambodia"},
    {flag: "https://flagcdn.com/w40/cm.jpg", value: "Cameroon", label: "Cameroon"},
    {flag: "https://flagcdn.com/w40/ca.jpg", value: "Canada", label: "Canada"},
    {flag: "https://flagcdn.com/w40/cv.jpg", value: "Cape Verde", label: "Cape Verde"},
    {flag: "https://flagcdn.com/w40/bq.jpg", value: "Caribbean Netherlands", label: "Caribbean Netherlands"},
    {flag: "https://flagcdn.com/w40/ky.jpg", value: "Cayman Islands", label: "Cayman Islands"},
    {flag: "https://flagcdn.com/w40/cf.jpg", value: "Central African Republic", label: "Central African Republic"},
    {flag: "https://flagcdn.com/w40/td.jpg", value: "Chad", label: "Chad"},
    {flag: "https://flagcdn.com/w40/cl.jpg", value: "Chile", label: "Chile"},
    {flag: "https://flagcdn.com/w40/cn.jpg", value: "China", label: "China"},
    {flag: "https://flagcdn.com/w40/cx.jpg", value: "Christmas Island", label: "Christmas Island"},
    {flag: "https://flagcdn.com/w40/cc.jpg", value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands"},
    {flag: "https://flagcdn.com/w40/co.jpg", value: "Colombia", label: "Colombia"},
    {flag: "https://flagcdn.com/w40/km.jpg", value: "Comoros", label: "Comoros"},
    {flag: "https://flagcdn.com/w40/ck.jpg", value: "Cook Islands", label: "Cook Islands"},
    {flag: "https://flagcdn.com/w40/cr.jpg", value: "Costa Rica", label: "Costa Rica"},
    {flag: "https://flagcdn.com/w40/ci.jpg", value: "Côte d'Ivoire (Ivory Coast)", label: "Côte d'Ivoire (Ivory Coast)"},
    {flag: "https://flagcdn.com/w40/hr.jpg", value: "Croatia", label: "Croatia"},
    {flag: "https://flagcdn.com/w40/cu.jpg", value: "Cuba", label: "Cuba"},
    {flag: "https://flagcdn.com/w40/cw.jpg", value: "Curaçao", label: "Curaçao"},
    {flag: "https://flagcdn.com/w40/cy.jpg", value: "Cyprus", label: "Cyprus"},
    {flag: "https://flagcdn.com/w40/cz.jpg", value: "Czechia", label: "Czechia"},
    {flag: "https://flagcdn.com/w40/dk.jpg", value: "Denmark", label: "Denmark"},
    {flag: "https://flagcdn.com/w40/dj.jpg", value: "Djibouti", label: "Djibouti"},
    {flag: "https://flagcdn.com/w40/dm.jpg", value: "Dominica", label: "Dominica"},
    {flag: "https://flagcdn.com/w40/do.jpg", value: "Dominican Republic", label: "Dominican Republic"},
    {flag: "https://flagcdn.com/w40/cd.jpg", value: "DR Congo", label: "DR Congo"},
    {flag: "https://flagcdn.com/w40/ec.jpg", value: "Ecuador", label: "Ecuador"},
    {flag: "https://flagcdn.com/w40/eg.jpg", value: "Egypt", label: "Egypt"},
    {flag: "https://flagcdn.com/w40/sv.jpg", value: "El Salvador", label: "El Salvador"},
    {flag: "https://flagcdn.com/w40/gq.jpg", value: "Equatorial Guinea", label: "Equatorial Guinea"},
    {flag: "https://flagcdn.com/w40/er.jpg", value: "Eritrea", label: "Eritrea"},
    {flag: "https://flagcdn.com/w40/ee.jpg", value: "Estonia", label: "Estonia"},
    {flag: "https://flagcdn.com/w40/sz.jpg", value: "Eswatini (Swaziland)", label: "Eswatini (Swaziland)"},
    {flag: "https://flagcdn.com/w40/et.jpg", value: "Ethiopia", label: "Ethiopia"},
    {flag: "https://flagcdn.com/w40/eu.jpg", value: "European Union", label: "European Union"},
    {flag: "https://flagcdn.com/w40/fk.jpg", value: "Falkland Islands", label: "Falkland Islands"},
    {flag: "https://flagcdn.com/w40/fo.jpg", value: "Faroe Islands", label: "Faroe Islands"},
    {flag: "https://flagcdn.com/w40/fj.jpg", value: "Fiji", label: "Fiji"},
    {flag: "https://flagcdn.com/w40/fi.jpg", value: "Finland", label: "Finland"},
    {flag: "https://flagcdn.com/w40/fr.jpg", value: "France", label: "France"},
    {flag: "https://flagcdn.com/w40/gf.jpg", value: "French Guiana", label: "French Guiana"},
    {flag: "https://flagcdn.com/w40/pf.jpg", value: "French Polynesia", label: "French Polynesia"},
    {flag: "https://flagcdn.com/w40/tf.jpg", value: "French Southern and Antarctic Lands", label: "French Southern and Antarctic Lands"},
    {flag: "https://flagcdn.com/w40/ga.jpg", value: "Gabon", label: "Gabon"},
    {flag: "https://flagcdn.com/w40/gm.jpg", value: "Gambia", label: "Gambia"},
    {flag: "https://flagcdn.com/w40/ge.jpg", value: "Georgia", label: "Georgia"},
    {flag: "https://flagcdn.com/w40/de.jpg", value: "Germany", label: "Germany"},
    {flag: "https://flagcdn.com/w40/gh.jpg", value: "Ghana", label: "Ghana"},
    {flag: "https://flagcdn.com/w40/gi.jpg", value: "Gibraltar", label: "Gibraltar"},
    {flag: "https://flagcdn.com/w40/gr.jpg", value: "Greece", label: "Greece"},
    {flag: "https://flagcdn.com/w40/gl.jpg", value: "Greenland", label: "Greenland"},
    {flag: "https://flagcdn.com/w40/gd.jpg", value: "Grenada", label: "Grenada"},
    {flag: "https://flagcdn.com/w40/gp.jpg", value: "Guadeloupe", label: "Guadeloupe"},
    {flag: "https://flagcdn.com/w40/gu.jpg", value: "Guam", label: "Guam"},
    {flag: "https://flagcdn.com/w40/gt.jpg", value: "Guatemala", label: "Guatemala"},
    {flag: "https://flagcdn.com/w40/gg.jpg", value: "Guernsey", label: "Guernsey"},
    {flag: "https://flagcdn.com/w40/gw.jpg", value: "Guinea-Bissau", label: "Guinea-Bissau"},
    {flag: "https://flagcdn.com/w40/gn.jpg", value: "Guinea", label: "Guinea"},
    {flag: "https://flagcdn.com/w40/gy.jpg", value: "Guyana", label: "Guyana"},
    {flag: "https://flagcdn.com/w40/ht.jpg", value: "Haiti", label: "Haiti"},
    {flag: "https://flagcdn.com/w40/hm.jpg", value: "Heard Island and McDonald Islands", label: "Heard Island and McDonald Islands"},
    {flag: "https://flagcdn.com/w40/hn.jpg", value: "Honduras", label: "Honduras"},
    {flag: "https://flagcdn.com/w40/hk.jpg", value: "Hong Kong", label: "Hong Kong"},
    {flag: "https://flagcdn.com/w40/hu.jpg", value: "Hungary", label: "Hungary"},
    {flag: "https://flagcdn.com/w40/is.jpg", value: "Iceland", label: "Iceland"},
    {flag: "https://flagcdn.com/w40/in.jpg", value: "India", label: "India"},
    {flag: "https://flagcdn.com/w40/id.jpg", value: "Indonesia", label: "Indonesia"},
    {flag: "https://flagcdn.com/w40/ir.jpg", value: "Iran", label: "Iran"},
    {flag: "https://flagcdn.com/w40/iq.jpg", value: "Iraq", label: "Iraq"},
    {flag: "https://flagcdn.com/w40/ie.jpg", value: "Ireland", label: "Ireland"},
    {flag: "https://flagcdn.com/w40/im.jpg", value: "Isle of Man", label: "Isle of Man"},
    {flag: "https://flagcdn.com/w40/il.jpg", value: "Israel", label: "Israel"},
    {flag: "https://flagcdn.com/w40/it.jpg", value: "Italy", label: "Italy"},
    {flag: "https://flagcdn.com/w40/jm.jpg", value: "Jamaica", label: "Jamaica"},
    {flag: "https://flagcdn.com/w40/jp.jpg", value: "Japan", label: "Japan"},
    {flag: "https://flagcdn.com/w40/je.jpg", value: "Jersey", label: "Jersey"},
    {flag: "https://flagcdn.com/w40/jo.jpg", value: "Jordan", label: "Jordan"},
    {flag: "https://flagcdn.com/w40/kz.jpg", value: "Kazakhstan", label: "Kazakhstan"},
    {flag: "https://flagcdn.com/w40/ke.jpg", value: "Kenya", label: "Kenya"},
    {flag: "https://flagcdn.com/w40/ki.jpg", value: "Kiribati", label: "Kiribati"},
    {flag: "https://flagcdn.com/w40/xk.jpg", value: "Kosovo", label: "Kosovo"},
    {flag: "https://flagcdn.com/w40/kw.jpg", value: "Kuwait", label: "Kuwait"},
    {flag: "https://flagcdn.com/w40/kg.jpg", value: "Kyrgyzstan", label: "Kyrgyzstan"},
    {flag: "https://flagcdn.com/w40/la.jpg", value: "Laos", label: "Laos"},
    {flag: "https://flagcdn.com/w40/lv.jpg", value: "Latvia", label: "Latvia"},
    {flag: "https://flagcdn.com/w40/lb.jpg", value: "Lebanon", label: "Lebanon"},
    {flag: "https://flagcdn.com/w40/ls.jpg", value: "Lesotho", label: "Lesotho"},
    {flag: "https://flagcdn.com/w40/lr.jpg", value: "Liberia", label: "Liberia"},
    {flag: "https://flagcdn.com/w40/ly.jpg", value: "Libya", label: "Libya"},
    {flag: "https://flagcdn.com/w40/li.jpg", value: "Liechtenstein", label: "Liechtenstein"},
    {flag: "https://flagcdn.com/w40/lt.jpg", value: "Lithuania", label: "Lithuania"},
    {flag: "https://flagcdn.com/w40/lu.jpg", value: "Luxembourg", label: "Luxembourg"},
    {flag: "https://flagcdn.com/w40/mo.jpg", value: "Macau", label: "Macau"},
    {flag: "https://flagcdn.com/w40/mg.jpg", value: "Madagascar", label: "Madagascar"},
    {flag: "https://flagcdn.com/w40/mw.jpg", value: "Malawi", label: "Malawi"},
    {flag: "https://flagcdn.com/w40/my.jpg", value: "Malaysia", label: "Malaysia"},
    {flag: "https://flagcdn.com/w40/mv.jpg", value: "Maldives", label: "Maldives"},
    {flag: "https://flagcdn.com/w40/ml.jpg", value: "Mali", label: "Mali"},
    {flag: "https://flagcdn.com/w40/mt.jpg", value: "Malta", label: "Malta"},
    {flag: "https://flagcdn.com/w40/mh.jpg", value: "Marshall Islands", label: "Marshall Islands"},
    {flag: "https://flagcdn.com/w40/mq.jpg", value: "Martinique", label: "Martinique"},
    {flag: "https://flagcdn.com/w40/mr.jpg", value: "Mauritania", label: "Mauritania"},
    {flag: "https://flagcdn.com/w40/mu.jpg", value: "Mauritius", label: "Mauritius"},
    {flag: "https://flagcdn.com/w40/yt.jpg", value: "Mayotte", label: "Mayotte"},
    {flag: "https://flagcdn.com/w40/mx.jpg", value: "Mexico", label: "Mexico"},
    {flag: "https://flagcdn.com/w40/fm.jpg", value: "Micronesia", label: "Micronesia"},
    {flag: "https://flagcdn.com/w40/md.jpg", value: "Moldova", label: "Moldova"},
    {flag: "https://flagcdn.com/w40/mc.jpg", value: "Monaco", label: "Monaco"},
    {flag: "https://flagcdn.com/w40/mn.jpg", value: "Mongolia", label: "Mongolia"},
    {flag: "https://flagcdn.com/w40/me.jpg", value: "Montenegro", label: "Montenegro"},
    {flag: "https://flagcdn.com/w40/ms.jpg", value: "Montserrat", label: "Montserrat"},
    {flag: "https://flagcdn.com/w40/ma.jpg", value: "Morocco", label: "Morocco"},
    {flag: "https://flagcdn.com/w40/mz.jpg", value: "Mozambique", label: "Mozambique"},
    {flag: "https://flagcdn.com/w40/mm.jpg", value: "Myanmar", label: "Myanmar"},
    {flag: "https://flagcdn.com/w40/na.jpg", value: "Namibia", label: "Namibia"},
    {flag: "https://flagcdn.com/w40/nr.jpg", value: "Nauru", label: "Nauru"},
    {flag: "https://flagcdn.com/w40/np.jpg", value: "Nepal", label: "Nepal"},
    {flag: "https://flagcdn.com/w40/nl.jpg", value: "Netherlands", label: "Netherlands"},
    {flag: "https://flagcdn.com/w40/nc.jpg", value: "New Caledonia", label: "New Caledonia"},
    {flag: "https://flagcdn.com/w40/nz.jpg", value: "New Zealand", label: "New Zealand"},
    {flag: "https://flagcdn.com/w40/ni.jpg", value: "Nicaragua", label: "Nicaragua"},
    {flag: "https://flagcdn.com/w40/ne.jpg", value: "Niger", label: "Niger"},
    {flag: "https://flagcdn.com/w40/ng.jpg", value: "Nigeria", label: "Nigeria"},
    {flag: "https://flagcdn.com/w40/nu.jpg", value: "Niue", label: "Niue"},
    {flag: "https://flagcdn.com/w40/nf.jpg", value: "Norfolk Island", label: "Norfolk Island"},
    {flag: "https://flagcdn.com/w40/kp.jpg", value: "North Korea", label: "North Korea"},
    {flag: "https://flagcdn.com/w40/mk.jpg", value: "North Macedonia", label: "North Macedonia"},
    {flag: "https://flagcdn.com/w40/mp.jpg", value: "Northern Mariana Islands", label: "Northern Mariana Islands"},
    {flag: "https://flagcdn.com/w40/no.jpg", value: "Norway", label: "Norway"},
    {flag: "https://flagcdn.com/w40/om.jpg", value: "Oman", label: "Oman"},
    {flag: "https://flagcdn.com/w40/pk.jpg", value: "Pakistan", label: "Pakistan"},
    {flag: "https://flagcdn.com/w40/pw.jpg", value: "Palau", label: "Palau"},
    {flag: "https://flagcdn.com/w40/ps.jpg", value: "Palestine", label: "Palestine"},
    {flag: "https://flagcdn.com/w40/pa.jpg", value: "Panama", label: "Panama"},
    {flag: "https://flagcdn.com/w40/pg.jpg", value: "Papua New Guinea", label: "Papua New Guinea"},
    {flag: "https://flagcdn.com/w40/py.jpg", value: "Paraguay", label: "Paraguay"},
    {flag: "https://flagcdn.com/w40/pe.jpg", value: "Peru", label: "Peru"},
    {flag: "https://flagcdn.com/w40/ph.jpg", value: "Philippines", label: "Philippines"},
    {flag: "https://flagcdn.com/w40/pn.jpg", value: "Pitcairn Islands", label: "Pitcairn Islands"},
    {flag: "https://flagcdn.com/w40/pl.jpg", value: "Poland", label: "Poland"},
    {flag: "https://flagcdn.com/w40/pt.jpg", value: "Portugal", label: "Portugal"},
    {flag: "https://flagcdn.com/w40/pr.jpg", value: "Puerto Rico", label: "Puerto Rico"},
    {flag: "https://flagcdn.com/w40/qa.jpg", value: "Qatar", label: "Qatar"},
    {flag: "https://flagcdn.com/w40/cg.jpg", value: "Republic of the Congo", label: "Republic of the Congo"},
    {flag: "https://flagcdn.com/w40/re.jpg", value: "Réunion", label: "Réunion"},
    {flag: "https://flagcdn.com/w40/ro.jpg", value: "Romania", label: "Romania"},
    {flag: "https://flagcdn.com/w40/ru.jpg", value: "Russia", label: "Russia"},
    {flag: "https://flagcdn.com/w40/rw.jpg", value: "Rwanda", label: "Rwanda"},
    {flag: "https://flagcdn.com/w40/bl.jpg", value: "Saint Barthélemy", label: "Saint Barthélemy"},
    {flag: "https://flagcdn.com/w40/sh.jpg", value: "Saint Helena, Ascension and Tristan da Cunha",	label: "Saint Helena, Ascension and Tristan da Cunha"},
    {flag: "https://flagcdn.com/w40/kn.jpg", value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis"},
    {flag: "https://flagcdn.com/w40/lc.jpg", value: "Saint Lucia", label: "Saint Lucia"},
    {flag: "https://flagcdn.com/w40/mf.jpg", value: "Saint Martin", label: "Saint Martin"},
    {flag: "https://flagcdn.com/w40/pm.jpg", value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon"},
    {flag: "https://flagcdn.com/w40/vc.jpg", value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines"},
    {flag: "https://flagcdn.com/w40/ws.jpg", value: "Samoa", label: "Samoa"},
    {flag: "https://flagcdn.com/w40/sm.jpg", value: "San Marino", label: "San Marino"},
    {flag: "https://flagcdn.com/w40/st.jpg", value: "São Tomé and Príncipe", label: "São Tomé and Príncipe"},
    {flag: "https://flagcdn.com/w40/sa.jpg", value: "Saudi Arabia", label: "Saudi Arabia"},
    {flag: "https://flagcdn.com/w40/sn.jpg", value: "Senegal", label: "Senegal"},
    {flag: "https://flagcdn.com/w40/rs.jpg", value: "Serbia", label: "Serbia"},
    {flag: "https://flagcdn.com/w40/sc.jpg", value: "Seychelles", label: "Seychelles"},
    {flag: "https://flagcdn.com/w40/sl.jpg", value: "Sierra Leone", label: "Sierra Leone"},
    {flag: "https://flagcdn.com/w40/sg.jpg", value: "Singapore", label: "Singapore"},
    {flag: "https://flagcdn.com/w40/sx.jpg", value: "Sint Maarten", label: "Sint Maarten"},
    {flag: "https://flagcdn.com/w40/sk.jpg", value: "Slovakia", label: "Slovakia"},
    {flag: "https://flagcdn.com/w40/si.jpg", value: "Slovenia", label: "Slovenia"},
    {flag: "https://flagcdn.com/w40/sb.jpg", value: "Solomon Islands", label: "Solomon Islands"},
    {flag: "https://flagcdn.com/w40/so.jpg", value: "Somalia", label: "Somalia"},
    {flag: "https://flagcdn.com/w40/za.jpg", value: "South Africa", label: "South Africa"},
    {flag: "https://flagcdn.com/w40/gs.jpg", value: "South Georgia", label: "South Georgia"},
    {flag: "https://flagcdn.com/w40/kr.jpg", value: "South Korea", label: "South Korea"},
    {flag: "https://flagcdn.com/w40/ss.jpg", value: "South Sudan", label: "South Sudan"},
    {flag: "https://flagcdn.com/w40/es.jpg", value: "Spain", label: "Spain"},
    {flag: "https://flagcdn.com/w40/lk.jpg", value: "Sri Lanka", label: "Sri Lanka"},
    {flag: "https://flagcdn.com/w40/sd.jpg", value: "Sudan", label: "Sudan"},
    {flag: "https://flagcdn.com/w40/sr.jpg", value: "Suriname", label: "Suriname"},
    {flag: "https://flagcdn.com/w40/sj.jpg", value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen"},
    {flag: "https://flagcdn.com/w40/se.jpg", value: "Sweden", label: "Sweden"},
    {flag: "https://flagcdn.com/w40/ch.jpg", value: "Switzerland", label: "Switzerland"},
    {flag: "https://flagcdn.com/w40/sy.jpg", value: "Syria", label: "Syria"},
    {flag: "https://flagcdn.com/w40/tw.jpg", value: "Taiwan", label: "Taiwan"},
    {flag: "https://flagcdn.com/w40/tj.jpg", value: "Tajikistan", label: "Tajikistan"},
    {flag: "https://flagcdn.com/w40/tz.jpg", value: "Tanzania", label: "Tanzania"},
    {flag: "https://flagcdn.com/w40/th.jpg", value: "Thailand", label: "Thailand"},
    {flag: "https://flagcdn.com/w40/tl.jpg", value: "Timor-Leste", label: "Timor-Leste"},
    {flag: "https://flagcdn.com/w40/tg.jpg", value: "Togo", label: "Togo"},
    {flag: "https://flagcdn.com/w40/tk.jpg", value: "Tokelau", label: "Tokelau"},
    {flag: "https://flagcdn.com/w40/to.jpg", value: "Tonga", label: "Tonga"},
    {flag: "https://flagcdn.com/w40/tt.jpg", value: "Trinidad and Tobago", label: "Trinidad and Tobago"},
    {flag: "https://flagcdn.com/w40/tn.jpg", value: "Tunisia", label: "Tunisia"},
    {flag: "https://flagcdn.com/w40/tr.jpg", value: "Turkey", label: "Turkey"},
    {flag: "https://flagcdn.com/w40/tm.jpg", value: "Turkmenistan", label: "Turkmenistan"},
    {flag: "https://flagcdn.com/w40/tc.jpg", value: "Turks and Caicos Islands", label: "Turks and Caicos Islands"},
    {flag: "https://flagcdn.com/w40/tv.jpg", value: "Tuvalu", label: "Tuvalu"},
    {flag: "https://flagcdn.com/w40/ug.jpg", value: "Uganda", label: "Uganda"},
    {flag: "https://flagcdn.com/w40/ua.jpg", value: "Ukraine", label: "Ukraine"},
    {flag: "https://flagcdn.com/w40/ae.jpg", value: "United Arab Emirates", label: "United Arab Emirates"},
    {flag: "https://flagcdn.com/w40/gb.jpg", value: "United Kingdom", label: "United Kingdom"},
    {flag: "https://flagcdn.com/w40/um.jpg", value: "United States Minor Outlying Islands", label: "United States Minor Outlying Islands"},
    {flag: "https://flagcdn.com/w40/vi.jpg", value: "United States Virgin Islands", label: "United States Virgin Islands"},
    {flag: "https://flagcdn.com/w40/us.jpg", value: "United States", label: "United States"},
    {flag: "https://flagcdn.com/w40/uy.jpg", value: "Uruguay", label: "Uruguay"},
    {flag: "https://flagcdn.com/w40/uz.jpg", value: "Uzbekistan", label: "Uzbekistan"},
    {flag: "https://flagcdn.com/w40/vu.jpg", value: "Vanuatu", label: "Vanuatu"},
    {flag: "https://flagcdn.com/w40/va.jpg", value: "Vatican City (Holy See)", label: "Vatican City (Holy See)"},
    {flag: "https://flagcdn.com/w40/ve.jpg", value: "Venezuela", label: "Venezuela"},
    {flag: "https://flagcdn.com/w40/vn.jpg", value: "Vietnam", label: "Vietnam"},
    {flag: "https://flagcdn.com/w40/wf.jpg", value: "Wallis and Futuna", label: "Wallis and Futuna"},
    {flag: "https://flagcdn.com/w40/eh.jpg", value: "Western Sahara", label: "Western Sahara"},
    {flag: "https://flagcdn.com/w40/ye.jpg", value: "Yemen", label: "Yemen"},
    {flag: "https://flagcdn.com/w40/zm.jpg", value: "Zambia", label: "Zambia"},
    {flag: "https://flagcdn.com/w40/zw.jpg", value: "Zimbabwe", label: "Zimbabwe"},
];

const options = {
    statuses: statuses,
    ratings: ratings,
    difficulty: difficulty,
    countries: countries,
    ownership: ownership,
    format: format,
    acquisition: acquisition,
    acquisitionState: acquisitionState,
    acquisitionCompleteness: acquisitionCompleteness,
    itemCondition: itemCondition,
    region: region,
};

export default options;
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import { routes } from "./routes";
import 'bootstrap/dist/css/bootstrap.min.css'
import './Fonts.css'
import './App.css'
import NotFoundPage from "./pages/notfound/NotFoundPage";
import LandingPage from "./pages/landing/LandingPage";
import Login from "./components/auth/Login";
import ProtectedRoute from "./components/guard/ProtectedRoute";
import Register from "./components/auth/Register";
import AccountPage from "./pages/account/AccountPage";
import UpdateAccount from "./components/forms/UpdateAccount";
import UpdateGamePage from "./pages/game/UpdateGamePage";
import AddGamePage from "./pages/game/AddGamePage";
import ViewGamePage from "./pages/game/ViewGamePage";
import ViewGame from "./components/forms/ViewGame";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} >        
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path='*' element={<NotFoundPage />} />
        </Route>

        {/* All routes nested under <ProtectedRoute/> require authentication */}
        <Route element={<ProtectedRoute />}>
          <Route path="/main" element={<MainLayout />}>

            {/* User Account route */}
            <Route path="/main/account" element={<AccountPage />} />
            <Route path="/main/account/update" element={<UpdateAccount />} />

            {/* Sidebar routes */}
            {routes}

            {/* Gaming routes */}
            <Route path="/main/games/add" element={<AddGamePage />} />
            <Route path="/main/games/view" element={<ViewGamePage />} >
              <Route path="/main/games/view:id" element={<ViewGamePage />} />
            </Route>
            <Route path="/main/games/page" element={<ViewGame />} >
              <Route path="/main/games/page:id" element={<ViewGame />} />
            </Route>
            <Route path="/main/games/update" element={<UpdateGamePage />} >
              <Route path="/main/games/update:id" element={<UpdateGamePage />} />
            </Route>

            <Route path='*' element={<NotFoundPage />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

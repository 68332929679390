import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

type Props = {
    imageURL: string;
};

type Image = {
    height?: string;
    width?: string;
    class?: string;
};

const ImageModal = (props: Props) => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const RenderImage = (image: Image) => {
    return (
        <>
            <img src={props.imageURL} 
                onClick={handleShow} 
                className={image.class} 
                alt="game" width={image.width} height={image.height} loading="lazy" />
        </>
    )
  };

  return (
    <>
      <RenderImage class='d-block mx-lg-auto image-modal' height='300'/>

      <Modal
        dialogClassName='modal'
        show={show} 
        onHide={handleClose}
        centered
      >
        <Modal.Body className='modal-body'>
            <RenderImage width='100%'/>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ImageModal;
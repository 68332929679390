import { useEffect, useState } from "react";
import GameService from "../../services/GameService";
import useAlert from "../../hooks/userAlert";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import SportsEsportsOutlinedIcon from "@mui/icons-material/SportsEsportsOutlined";
import { IAccountConnections } from "../../types/AccountConnections";
import { Tooltip } from "@mui/material";
import { Button } from "react-bootstrap";
import { IAccount } from "../../types/Account";
import { IConnection } from "../../types/Connection";
import sessionManager from "../../utils/SessionManager";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  formatTimestampWithMonthName,
  formatUserName,
} from "../../utils/Formatter";
import { BackgroundImageOutletContext } from "../../components/layout/MainLayout";
import { setThemeBackground } from "../../utils/Display";

type Props = {};

const ListConnectionsPage = (props: Props) => {
  const canEdit = sessionManager.isAuthUserCurrentProfileOwner();

  const noGamingData = "No current gaming connections to be displayed.";

  const [isLoading, setIsLoading] = useState(false);
  const [accountConnections, setAccountConnections] = useState(
    {} as IAccountConnections
  );

  const navigate = useNavigate();
  const { setAlert } = useAlert();

  const fecthErrorMessage =
    "We had a problem while fetching gaming connections";
  const unfollowUserMessage = "You are no longer following ";
  const unfollowUserErrorMessage =
    "We had a problem while requesting to unfollow the selected user";

  const { setBackgroundImage } =
    useOutletContext<BackgroundImageOutletContext>();

  useEffect(() => {
    setThemeBackground(setBackgroundImage);
    fetchGamingConnections();
    // eslint-disable-next-line
  }, []);

  const handleResponseError = (error: any, message: string) => {
    setAlert(message, "error");
  };

  const fetchGamingConnections = async () => {
    try {
      setIsLoading(true);
      const response = await GameService.getUserConnections();
      setAccountConnections(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      handleResponseError(error, fecthErrorMessage);
    }
  };

  const goToUserProfile = async (e: any, account: IAccount) => {
    const userId = account.pk!.split("#")[1];
    await sessionManager.setCurrentUserProfile(userId);
    navigate("/main");
    document.location.reload();
  };

  const unfollowUserProfile = async (e: any, account: IAccount) => {
    const userId = account.pk!.split("#")[1];
    try {
      const request: IConnection = { sk: userId };
      await GameService.unfollowUserAccount(request);
      setAlert(unfollowUserMessage + account.display_name, "success");
    } catch (err: any) {
      setAlert(unfollowUserErrorMessage, "error");
    }
  };

  const RenderUserConnections = () => {
    return (
      <>
        <h6 className="page-header">
          <span className="filtered-value">
            {formatUserName(
              sessionManager.getCurrentUserProfile()?.display_name
            )}
          </span>{" "}
          Gaming Connections
        </h6>
        <p className="mb-2" />

        <div>
          <p className="mb-2" />
          <h3 className="page-title">Following</h3>
          <p className="mb-3" />
          <div className="container-fluid">
            {accountConnections.following?.length ? (
              <table className="table table-dark table-striped">
                <tbody>
                  {accountConnections.following?.map((account) => (
                    <tr>
                      <td>
                        {
                          <img
                            src={account.profile_image_url}
                            className="rounded float-left"
                            alt="avatar"
                            width="80"
                          />
                        }
                      </td>
                      <td className="align-middle">
                        <span className="large-text align-middle">
                          {account.display_name}{" "}
                        </span>
                        {account.location_image_url ? (
                          <img
                            src={account.location_image_url}
                            height={15}
                            className="align-middle"
                            alt="flag"
                          />
                        ) : (
                          <></>
                        )}
                        <br />
                        <span className="small-text">
                          Member since{" "}
                          {formatTimestampWithMonthName(account.created_at)}
                        </span>
                        <br />
                        <br />
                        {account.about_me}
                      </td>
                      <td className="align-middle">
                        <Tooltip title="Go to profile">
                          <Button
                            className="button-custom"
                            size="sm"
                            onClick={(e) => goToUserProfile(e, account)}
                          >
                            <SportsEsportsOutlinedIcon />
                          </Button>
                        </Tooltip>
                        <br />
                        <br />
                        {canEdit && (
                          <Tooltip title="Stop following user">
                            <Button
                              className="button-custom"
                              size="sm"
                              onClick={(e) => unfollowUserProfile(e, account)}
                            >
                              <PersonRemoveOutlinedIcon />
                            </Button>
                          </Tooltip>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="row pt-3">
                <h6>{noGamingData}</h6>
              </div>
            )}
          </div>
        </div>

        <div>
          <p className="mb-2" />
          <h3 className="page-title">Followers</h3>
          <p className="mb-3" />
          <div className="container-fluid">
            {accountConnections.followers?.length ? (
              <table className="table table-dark table-striped">
                <tbody>
                  {accountConnections.followers?.map((account) => (
                    <tr>
                      <td>
                        {
                          <img
                            src={account.profile_image_url}
                            className="rounded float-left"
                            alt="avatar"
                            width="80"
                          />
                        }
                      </td>
                      <td className="align-middle">
                        <span className="large-text align-middle">
                          {account.display_name}{" "}
                        </span>
                        {account.location_image_url ? (
                          <img
                            src={account.location_image_url}
                            height={15}
                            className="align-middle"
                            alt="flag"
                          />
                        ) : (
                          <></>
                        )}
                        <br />
                        <span className="small-text">
                          Member since{" "}
                          {formatTimestampWithMonthName(account.created_at)}
                        </span>
                        <br />
                        <br />
                        {account.about_me}
                      </td>
                      <td className="align-middle">
                        <Tooltip title="Go to profile">
                          <Button
                            className="button-custom"
                            size="sm"
                            onClick={(e) => goToUserProfile(e, account)}
                          >
                            <SportsEsportsOutlinedIcon />
                          </Button>
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="row pt-3">
                <h6>{noGamingData}</h6>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="container-fluid">
      {isLoading ? (
        <div className="spinner-grow" role="status" />
      ) : (
        <RenderUserConnections />
      )}
    </div>
  );
};

export default ListConnectionsPage;

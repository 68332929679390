import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import GameService from "../../services/GameService";
import UserMenu from "../menu/UserMenu";
import useAlert from "../../hooks/userAlert";
import MenuIcon from '@mui/icons-material/Menu';

type Props = {
  handleDrawerToggle: () => void,
};

const Topbar = (props: Props) => {

  const navigate = useNavigate();
  const { setAlert } = useAlert();

  const [searchValue, setSearchValue] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {}, [searchValue]);

  const fecthErrorMessage = 'We had a problem while fetching the search results';

  const onChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = async (e: any) => {
		e.preventDefault();
    try {
      setIsLoading(true);
			const res = await GameService.searchGamesByName(searchValue);
      const results = res.data;
      setIsLoading(false);

      navigate("/main/search/results", { replace: true, state: {results} });
		} catch (err: any) {
      setIsLoading(false);
			console.log(err.message);
      setAlert(fecthErrorMessage, 'error');
		}
	};

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${sizeConfigs.sidebar.width})`,
                md: `calc(100% - ${sizeConfigs.sidebar.width})` },
        ml: { sm: `${sizeConfigs.sidebar.width}`,
              md: sizeConfigs.sidebar.width },
        boxShadow: "unset",
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color
      }}
    >
      <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={props.handleDrawerToggle}
            sx={{ color: colorConfigs.sidebar.bg, mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <form onSubmit={handleSearch} className="w-50">
            <div className="row">
              <div className="col">
                <div className="input-group shadow-none align-items-center">
                  <input type='text' name='game_search' className='form-control' placeholder='Game search...' onChange={onChange}/>
                  <div className="ml-2">
                    {isLoading ? 
                        <div className="spinner-grow m-1" role="status" /> :
                        <div></div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </form>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
          <UserMenu />
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
import { useLocation } from "react-router-dom";
import ListSearchResults from "../../components/search/ListSearchResults";

type Props = {};

const SearchPage = (props: Props) => {

  let results = null;

  const {state} = useLocation();
  if (state) {
    results = state.results;
  }

  const NoResults = () => {
    return (
      <div>Start a new game search to see matching results here.</div>
    )
  }
  
  if (results) {
    return <ListSearchResults games={results} />
  } else {
    return <NoResults/>
  }

};

export default SearchPage;
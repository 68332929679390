import { Box } from "@mui/material";
import GameCard from "../card/GameCard";
import SearchIcon from "@mui/icons-material/Search";
import SearchCardActions from "../card/SearchCardActions";
import { useEffect, useMemo, useState } from "react";
import gamesUtils from "../../utils/GamesUtils";
import SearchCardDisplay from "../card/SearchCardDisplay";
import { setThemeBackground } from "../../utils/Display";
import { useOutletContext } from "react-router-dom";
import { BackgroundImageOutletContext } from "../layout/MainLayout";

type Props = {
  games: [];
};

const ListSearchResults = (props: Props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { setBackgroundImage } =
    useOutletContext<BackgroundImageOutletContext>();

  const filteredGames = useMemo(() => {
    return gamesUtils.filterGames(searchTerm, props.games);
  }, [searchTerm, props.games]);

  useEffect(() => {
    setThemeBackground(setBackgroundImage);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="d-flex align-items-center">
          <span className="gaming-count">{props.games.length} </span>
          <span className="gaming-section">&nbsp; games found</span>
        </div>
      </div>
      {/* <div className="container"> */}

      <div className="row">
        <div className="col">
          <div className="pb-3">
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <div className="input-group-sm w-50">
                <SearchIcon sx={{ color: "white", mr: 0.5, my: 0.5 }} />
                <label>Find in results</label>
                <input
                  type="text"
                  className="form-control"
                  id="input-with-sx"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </Box>
          </div>
        </div>
      </div>

      {filteredGames.length === 0 && (
        <div className="row">
          <h6>
            No results found for games with title containg your search term.
          </h6>
          <p>Please adjust your search criteria and give it a try again.</p>
        </div>
      )}

      <div className="row no-gutters">
        {filteredGames.map((game, index) => (
          <div
            key={index}
            className="col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-2 p-2"
          >
            <GameCard
              game={game}
              display={<SearchCardDisplay game={game} />}
              action={<SearchCardActions game={game} />}
            />
          </div>
        ))}
      </div>

      {/* </div> */}
    </div>
  );
};

export default ListSearchResults;

import { IGame } from "../../types/Game";
import { useNavigate } from "react-router-dom";
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';

type Props = {
  game: IGame;
};

const SearchCardActions = (props: Props) => {

  const navigate = useNavigate();
  const viewGamePage = "/main/games/page/" + encodeURI(props.game.game_slug);

  const openGamePage = async (e: any) => {
    navigate(viewGamePage);
  };

  return (
    <div className="justify-content-between align-items-center">
      <button
        onClick={openGamePage}
        type="button"
        className="btn btn-sm button-outline-custom"
      >
        <SportsEsportsIcon/> More...
      </button>
    </div>
  );
};

export default SearchCardActions;
